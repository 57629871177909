import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { words: Array };

  connect() {
    this.words = [
      "Cafe",
      "Restaurant",
      "Shop",
      "Online Store",
      "Website",
      "Bookshop",
      "Event",
      "Meet Up",
      "Conference",
      "Concert",
      "Gig",
    ];
    this.index = 0;
    this.isDeleting = false; // Flag to check if it's deleting
    this.typingSpeed = 200; // Speed of typing effect (milliseconds)

    this.currentWord = this.words[0]; // start from the first word.
    this.currentWordSize = this.currentWord.length;
    this.currentCharIndex = 0;

    this.type(); // Start the typing animation
  }

  type() {
    if (this.isDeleting) {
      this.sleep = 100;
      this.currentCharIndex--;

      if (this.currentCharIndex === 0) {
        let randomNum = Math.floor(Math.random() * this.words.length);
        while (randomNum === this.index) {
          randomNum = Math.floor(Math.random() * this.words.length);
        }
        this.index = randomNum;
        this.currentWord = this.words[this.index];
        this.currentWordSize = this.currentWord.length;
        this.currentCharIndex = 0;
        this.isDeleting = false;
      }
    } else {
      this.sleep = 200;
      this.currentCharIndex++;

      if (this.currentCharIndex === this.currentWordSize) {
        this.isDeleting = true;
        this.sleep = 3000; // wait 5 saconds before next word
      }
    }

    // update element text
    this.element.textContent = this.currentWord.substring(
      0,
      this.currentCharIndex,
    );

    setTimeout(() => this.type(), this.sleep);
  }
}
