import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar", "overlay"];

  connect() {
    console.log("Admin sidebar controller connected.");
  }

  open() {
    console.log("open click");
    this.sidebarTarget.classList.remove("hidden");
    this.overlayTarget.classList.remove("hidden");
  }

  close() {
    console.log("close click");
    this.sidebarTarget.classList.add("hidden");
    this.overlayTarget.classList.add("hidden");
  }
}
