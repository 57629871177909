import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const categoryText = this.element.textContent.trim();

    this.isDeleting = false;
    this.typingSpeed = 200;
    this.wordSize = categoryText.length;
    this.currentCharIndex = 0;
    this.startTypingAnimation(categoryText);
  }

  startTypingAnimation(text) {
    if (this.isDeleting) {
      this.sleep = 100;
      this.currentCharIndex--;
      if (this.currentCharIndex === 0) {
        this.isDeleting = false;
      }
    } else {
      this.sleep = 200;
      this.currentCharIndex++;
      if (this.currentCharIndex === this.wordSize) {
        this.isDeleting = true;
        this.sleep = 7000;
      }
    }

    this.element.textContent = text.substring(0, this.currentCharIndex);

    setTimeout(() => this.startTypingAnimation(text), this.sleep);
  }
}
